@import './variables';

.scene {
  // border: 1px solid blue;
  width: 100%;
  height: 120vh;
  z-index: 9999999999999;
  box-sizing: border-box;
  margin-bottom: 20vh;
  // margin-top: -10px;
  .sticky-child {
    position: sticky;
    top: 0;
    left: 0;
    // border: 5px solid green;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .content {
      height: 100%;
      width: 100%;
      display: flex;
    }
    .in-view-trigger {
      width: 100%;
      // border: 5px solid pink;
      bottom: 0;
    }
  }
}
.last-scene{
  margin-bottom:0;
}
