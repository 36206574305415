.tech-icon {
  width: 24px;
  height: 24px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.342));
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
}
.filter-svg {
  filter: saturate(0%) brightness(0%) contrast(100%);
}
.filter-svg-white {
  filter: invert(1);
}
