body {
  background-color: black;
}

$animation-delay-step: 0.18s;

.container {
  /* border: 5px solid blue; */
  height: 200vh;
  width: 25vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: -12.5vw;
}

// container for however many cogs make up the component
.CogResponsive {
  @for $i from 1 through 99 {
    &:nth-child(#{$i}) {
      .cog-container {
        .cog-outer {
          animation: rotate-clockwise 10s infinite linear;
          animation-delay: calc($animation-delay-step * ($i * 2));
        }
      }
      .cog-container + .cog-container {
        filter: saturate(0.7);
        .cog-outer {
          animation: rotate-anticlockwise 5s infinite linear;
          animation-delay: calc(
            ($animation-delay-step * ($i * 2)) + $animation-delay-step
          );
        }
      }
    }
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  /* border: 5px solid pink; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: flex-start;
}

.cog-container {
  filter: drop-shadow(
    0px 0px 10px #000000
  ); // saturate(0.4)  hue-rotate(125deg)
  width: 100%;
  aspect-ratio: 1/1;
  overflow: visible;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 5px solid green; */
  box-sizing: border-box;
  margin-bottom: 0.5vw;
}

.cog-outer {
  border: 10px solid grey;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 10;
  animation: rotate-clockwise 10s infinite linear;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.cog-body-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: rgb(0, 111, 191);
  border: 20px solid rgb(0, 111, 191);
  z-index: 99;
}

.cogs-right {
  .CogResponsive {

      @for $i from 1 through 99 {
        &:nth-child(#{$i}) {
          .cog-container {
            .cog-outer {
              animation: rotate-anticlockwise 10s infinite linear;
              animation-delay: calc($animation-delay-step * ($i * 2));
            }
          }
          .cog-container + .cog-container {
            filter: saturate(0.7);
            .cog-outer {
              animation: rotate-clockwise 5s infinite linear;
              animation-delay: calc(
                ($animation-delay-step * ($i * 2)) + $animation-delay-step
              );
            }
          }
        }
      }
    
  }
}

.cog-hole-overlay {
  position: absolute;
  /* hole as percent of body */
  width: 85%;
  /* hole as percent of body */
  height: 85%;
  border-radius: 50%;
  box-shadow: inset 0 0 40px #000000;
  box-sizing: border-box;
  z-index: 100;
}

.cog-center-shaft-overlay {
  position: absolute;
  /* shaft as percent of body */
  width: 25%;
  /* shaft as percent of body */
  height: 25%;
  border-radius: 50%;
  background-color: rgb(0, 111, 191);
  filter: drop-shadow(0px 0px 10px #000000) brightness(0.9);
  border: 5px solid darken(rgb(0, 111, 191), 5%);
  box-sizing: border-box;
  z-index: 100;
}

.tooth {
  background-color: rgb(0, 111, 191);
  position: absolute;
  /* how far the tooth extends from cog */
  height: 15%;
  /* width of tooth. REMEMBER TO CHANGE MARGIN-LEFT */
  width: 14%;
  /* should be negative half of width */
  margin-left: -7%;
  /* places tooth on outside of cog */
  margin-top: 110%;
  left: 50%;
  right: 50%;
  border-radius: 0 0 0.5vw 0.5vw;
  z-index: -1;
}

.origin {
  position: absolute;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  z-index: 11111111;
}

.origin + .origin {
  transform: rotateZ(22.5deg);
}

.origin + .origin + .origin {
  transform: rotateZ(-22.5deg);
}

.origin + .origin + .origin + .origin {
  transform: rotateZ(45deg);
}

.origin + .origin + .origin + .origin + .origin {
  transform: rotateZ(-45deg);
}

.origin + .origin + .origin + .origin + .origin + .origin {
  transform: rotateZ(67.5deg);
}

.origin + .origin + .origin + .origin + .origin + .origin + .origin {
  transform: rotateZ(-67.5deg);
}

.origin + .origin + .origin + .origin + .origin + .origin + .origin + .origin {
  transform: rotateZ(90deg);
}

.origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin {
  transform: rotateZ(-90deg);
}

.origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin {
  transform: rotateZ(112.5deg);
}

.origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin {
  transform: rotateZ(-112.5deg);
}

.origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin {
  transform: rotateZ(135deg);
}

.origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin {
  transform: rotateZ(-135deg);
}

.origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin {
  transform: rotateZ(157.5deg);
}

.origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin {
  transform: rotateZ(-157.5deg);
}

.origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin
  + .origin {
  transform: rotateZ(180deg);
}

.cog-container + .cog-container {
  width: 50%;
  aspect-ratio: 1/1;
  filter: saturate(0.7);
  z-index: -1;
  .cog-outer {
    animation: rotate-anticlockwise 5s infinite linear;
    animation-delay: 0.18s;
  }
  .tooth {
    height: 18%; /* how far the tooth extends from cog */
    width: 29%; /* width of tooth. REMEMBER CHANGE MARGIN-LEFT */
    margin-left: -14.5%; /* should be negative half of width */
    margin-top: 120%; /* places tooth on outside of cog */
  }
  .origin {
    position: absolute;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    z-index: 11111111;
  }

  .origin + .origin {
    transform: rotateZ(45deg);
  }

  .origin + .origin + .origin {
    transform: rotateZ(-45deg);
  }

  .origin + .origin + .origin + .origin {
    transform: rotateZ(90deg);
  }

  .origin + .origin + .origin + .origin + .origin {
    transform: rotateZ(-90deg);
  }

  .origin + .origin + .origin + .origin + .origin + .origin {
    transform: rotateZ(135deg);
  }

  .origin + .origin + .origin + .origin + .origin + .origin + .origin {
    transform: rotateZ(-135deg);
  }

  .origin
    + .origin
    + .origin
    + .origin
    + .origin
    + .origin
    + .origin
    + .origin {
    transform: rotateZ(180deg);
  }
}

@keyframes rotate-clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-anticlockwise {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
