@import './variables';

$banner-offset: 10px;
$offset-multiplier: 5;
$banner-height: calc($banner-offset * $offset-multiplier);
$pole-color: rgb(125, 56, 21);

.SectionTitle {
  margin-right: 0;
  margin-left: 0;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  filter: drop-shadow(15px 15px 20px #0000006a);
  .banner {
    display: flex;
  }
  .platform {
    position: absolute;
    width: 100vw;
    top: 100vh;
    .platform-top,
    .platform-bottom {
      height: 2vh;
      background-color: rgb(138, 143, 178);
      border-top: 0.4vh solid lighten(rgb(138, 143, 178), 10%);
      border-bottom: 0.4vh solid darken(rgb(138, 143, 178), 10%);
      width: 100%;
      z-index: 900;
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.505));
    }
    .platform-top {
      position: absolute;
    }
    .platform-middle {
      margin-top: 1.5vh;
      width: 100%;
      height: 5vh;
      display: flex;
      justify-content: center;
      overflow: hidden;
      .girder-aframe {
        display: flex;
        margin-right: 4vh;
        .girder-strut {
          margin-top: -1.5vh;
          width: 1vh;
          height: 8vh;
          background-color: rgb(138, 143, 178);
          background: linear-gradient(
            0.25turn,
            darken(rgb(138, 143, 178), 10%),
            rgb(138, 143, 178),
            darken(rgb(138, 143, 178), 10%)
          );
        }
        .girder-strut-a {
          margin-right: 4vh;
          transform: rotate(45deg);
        }
        .girder-strut-b {
          transform: rotate(-45deg);
        }
      }
    }
  }
  .pole {
    position: absolute;
    width: 15px;
    height: 100vh;
    background-color: $pole-color;
    border-right: 5px solid darken($pole-color, 5%);
    top: 0;
    z-index: -1;
    filter: drop-shadow(15px 15px 20px #0000006a);
    margin-right: auto;
    margin-left: auto;
    margin-top: 3px;
  }
  .pole-left {
    left: 0;
    right: 40vw;
  }
  .pole-right {
    left: 40vw;
    right: 0;
  }
  .banner-center {
    background-color: $banner-color;
    padding: 0.9vh 2vw;
    height: $banner-height;
    border-radius: 5px 5px 5px 5px;
    color: white;
    display: flex;
    max-width: 80vw;
    padding: 0 20vw;
    display: flex;
    align-items: center;
    h2 {
      margin: 0;
      filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.489));
    }
    z-index: 1;
  }
  .banner-left,
  .banner-right {
    width: 2vw;
    min-width: 25px;
    background-color: darken($banner-color, 5%);
    margin-top: -6px;
    height: $banner-height;
  }
  .banner-left {
    margin-right: -$banner-offset;
    border-right: $banner-offset solid darken($banner-color, 40%);
    border-bottom: $banner-offset solid transparent;
    border-top: $banner-offset solid transparent;
    border-radius: 0px 5px 5px 0px;
  }
  .banner-right {
    margin-left: -$banner-offset;
    border-left: $banner-offset solid darken($banner-color, 40%);
    border-bottom: $banner-offset solid transparent;
    border-top: $banner-offset solid transparent;
    border-radius: 5px 0px 0px 0px;
  }
  .streamers-left {
    margin-right: -1px;
  }
  .streamers-right {
    margin-left: -1px;
  }
  .streamer-top-left {
    margin-top: -6px;
    width: 30px;
    height: calc($banner-height / 2);
    border-top: calc($banner-height / 2) solid darken($banner-color, 5%);
    border-left: 30px solid transparent;
  }
  .streamer-top-right {
    margin-top: -6px;
    width: 30px;
    height: calc($banner-height / 2);
    border-top: calc($banner-height / 2) solid darken($banner-color, 5%);
    border-right: 30px solid transparent;
  }
  .streamer-bottom-left {
    width: 30px;
    height: calc($banner-height / 2);
    border-bottom: calc($banner-height / 2) solid darken($banner-color, 5%);
    border-left: 30px solid transparent;
  }
  .streamer-bottom-right {
    width: 30px;
    height: calc($banner-height / 2);
    border-bottom: calc($banner-height / 2) solid darken($banner-color, 5%);
    border-right: 30px solid transparent;
  }

  .tooth + .tooth {
    transform: rotateZ(22.5deg);
  }

  .tooth + .tooth + .tooth {
    transform: rotateZ(-22.5deg);
  }

  .tooth + .tooth + .tooth + .tooth {
    transform: rotateZ(45deg);
  }

  .tooth + .tooth + .tooth + .tooth + .tooth {
    transform: rotateZ(-45deg);
  }

  .tooth + .tooth + .tooth + .tooth + .tooth + .tooth {
    transform: rotateZ(67.5deg);
  }

  .tooth + .tooth + .tooth + .tooth + .tooth + .tooth + .tooth {
    transform: rotateZ(-67.5deg);
  }

  .tooth + .tooth + .tooth + .tooth + .tooth + .tooth + .tooth + .tooth {
    transform: rotateZ(90deg);
  }
  .cog-container {
    position: absolute;
    height: 1px;
    top: 42px;
    margin-top: 0px;
    transform: scale(0.75);
    z-index: -1;
    .cog {
      background-color: rgb(0, 111, 191);
      width: 150px;
      height: 150px;
      border-radius: 50%;
      z-index: -1;
      animation: spin 10s infinite linear;
    }
    .tooth {
      background-color: rgb(0, 111, 191);
      position: absolute;
      height: 175px;
      width: 15px;
      margin-left: -7.5px;
      margin-top: -12.5px;
      left: 50%;
      border-radius: 2px;
      z-index: 10;
    }
    .cog-center {
      position: absolute;
      width: 130px;
      height: 130px;
      margin-left: 10px;
      margin-top: 10px;
      background-color: darken(rgb(0, 111, 191), 10%);
      border-radius: 50%;
      z-index: 3000;
      box-shadow: inset 0 0 90px darken(rgb(0, 111, 191), 40%);
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(-360deg);
      }
    }
    @keyframes spin2 {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .pole-left {
      right: 50vw;
    }
    .pole-right {
      left: 50vw;
    }
  }
}
