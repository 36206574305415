.ProjectScene {
  height: 200vh;
  .sticky-child {
    height: 120vh;
    // border: 5px solid green;
  }
  .SectionTitle {
    // position: sticky;
  }
  .project-container {
    position: absolute;
    width: 100%;
    // height: 100vh;
    // border: 2px solid lightseagreen;
    z-index: 100000;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    overflow-x: hidden;
    .project-a,
    .project-b {
      width: 40vw;
      height: 75vh;
      background-color: white;
      padding: 10px;
      display: flex;
      align-items: flex-start;
      filter: drop-shadow(0px 0px 10px black);
      .project-content {
        .skills-column {
          p {
            padding: 0;
            margin: 0 1vw;
          }
        }
      }
      p {
        font-size: 1.1vw;
        padding: 0px 30px;
        text-align: left;
      }
      .project-image {
        border: 1px solid rgb(162, 162, 162);
        filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.295));
        margin: 5px 0px;
        width: 90%;
      }
      .project-links-container {
        position: absolute;
        // margin-left: 36.5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 36.5vw;
        .project-link {
          width: 7vw;
          aspect-ratio: 1/1;
          margin: 1vh 0px;
          background-color: #d14545;
          color: white;
          border-radius: 2vw;
          display: flex;
          align-items: center;
          justify-content: center;
          filter: drop-shadow(0px 0px 20px rgb(0, 0, 0));
          &:hover {
            transform: translate(0, -1vh);
          }
          &:active {
            transform: rotate(5deg);
          }
          p {
            text-align: center;
          }
        }
      }
      h4 {
        margin: 0px 5px;
        margin-top: -17px;
        background-color: white;
        padding: 5px;
      }
      h3 {
        font-size: 1.2vw;
      }
    }
    .project-a {
      padding-left: 10px;
    }
    .project-b {
      padding-right: 10px;
      .project-links-container {
        margin-left: -5vw;
      }
    }
  }
  .project-container-1 {
    margin-top: 10vh;
  }
  .project-container-2 {
    margin-top: 90vh;
  }
}
@media only screen and (max-width: 600px) {
  .ProjectScene {
    height: 360vh;
    .project-container {
      flex-direction: column;
      height: 155vh;
      align-items: center;
      .project-a,
      .project-b {
        width: 95vw;
        .project-links-container {
          top: 5vh;
          margin-left: 0;
          margin-right: -76vw;
          left: 0;
          right: 0;
          .project-link {
            width: 20vw;
            p {
              font-size: 3.6vw;
              text-align: center;
            }
          }
        }
        .project-content {
          h3 {
            font-size: 4vw;
          }
          p {
            font-size: 3.5vw;
          }
          .skills-column {
            img {
              width: 4vw;
            }
            p {
              padding: 0;
            }
          }
        }
      }
    }
    .project-container-1 {
      margin-top: 20vh;
      height: 155vh;
    }
    .sticky-child {
      z-index: 99999999999999;
      .pole {
        display: none;
      }
    }
    .project-container-2 {
      margin-top: 180vh;
    }
  }
}
