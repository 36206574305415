@import url('https://fonts.googleapis.com/css2?family=Recursive:wght@300;400;500;600;800&display=swap');
@import './variables';
$wallpaper-color: rgb(2, 41, 73);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Recursive', sans-serif;
}
body {
  overflow-x: hidden;
}
.scrolling-visual-element {
  position: absolute;
  // background-color: hotpink;
  // border: 5px solid pink;
  width: 100vw;
  height: 742vh;
  top: 0;
  z-index: -9;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  .cogs-left,
  .cogs-right {
    // border: 2vw solid rgb(73, 4, 4);
    width: 20vw;
    height: 100%;
    box-sizing: border-box;
    border-radius: 10vw;
  }
  .cogs-left {
    margin-left: -12vw;
    .CogResponsive {
      left: -4vw;
      top: -1vh;
    }
  }
  .cogs-right {
    margin-right: -9vw;
    .CogResponsive {
      top: -1vh;
      align-items: flex-start;
    }
  }
}

.background {
  position: fixed;
  top: 0;
  width: 100%;
  height: 200vh;
  z-index: -10;
  background-color: rgb(25, 51, 72);
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: linear-gradient(
    to right,
    $wallpaper-color,
    $wallpaper-color 40px,
    lighten($wallpaper-color, 10%) 20px,
    lighten($wallpaper-color, 10%)
  );
  background-size: 80px 100%;
}
.background-overlay {
  position: fixed;
  top: 0;
  left: -20px;
  width: 100vw;
  height: 1px;
  z-index: -8;
  background-color: rgb(0, 0, 50);
  box-shadow: 0px 10px 50vh 40vh rgba(0, 170, 255, 0.826);
  mix-blend-mode: multiply;
}
.App {
  font-family: sans-serif;
  text-align: center;
  .menu-toggle-container {
    position: fixed;
    bottom: 3vw;
    right: 3vw;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(0px 0px 10px black);
    .triangle {
      width: 0;
      height: 0;
      border-top: 2vw solid $button-red;
      border-left: 2vw solid transparent;
      border-right: 2vw solid transparent;
      margin-bottom: 0.4vw;
      transition: transform 0.1s;
    }
    .flipped {
      transform: scale(0.25, 1);
    }
    .prompt {
      max-width: 6vw;
      background-color: white;
      padding: 1vw;
      border-radius: 1vw;
      font-size: 1vw;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: visibility 0.1s;
      transition: height 0.1s;
      margin: 0.5vw 0;
    }
    .nav-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      transition: visibility 0.1s;
      .nav-link {
        filter: drop-shadow(0px 0px 5px black);
        min-width: 7vw;
        font-size: 1.5vw;
        padding: 1vw;
        margin-top: 0.2vw;
        border-radius: 1vw;
        color: white;
        background-color: $button-red;
        border: none;
        &:hover {
          transform: translate(-0.5vw, 0);
          cursor: pointer;
        }
        &:active {
          transform: rotate(10deg);
        }
      }
    }
    .nav-hidden {
      display: none;
      max-height: 0;
    }
    .transparent {
      visibility: hidden;
    }
    .menu-toggle {
      width: 6vw;
      height: 6vw;
      border-radius: 1vw;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 4vw;
      background-color: $button-red;
      border-left: 0.5vw solid lighten($button-red, 7%);
      border-top: 0.5vw solid lighten($button-red, 10%);
      border-bottom: 0.5vw solid darken($button-red, 10%);
      border-right: 0.5vw solid darken($button-red, 7%);
      p {
        color: white;
        margin-top: -0.5vw;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .menu-toggle-active {
      background-color: darken($button-red, 10%);
      border-right: 0.5vw solid lighten($button-red, 4%);
      border-bottom: 0.5vw solid lighten($button-red, 7%);
      border-top: 0.5vw solid darken($button-red, 16%);
      border-left: 0.5vw solid darken($button-red, 13%);
      p {
        color: lightgrey;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .App {
    .menu-toggle-container {
      bottom: 33px;
      right: 5vw;
      z-index: 999999;
      filter: drop-shadow(0px 0px 10px black);
      .triangle {
        border-top: 5vw solid $button-red;
        border-left: 5vw solid transparent;
        border-right: 5vw solid transparent;
        margin-bottom: 1vw;
      }
      .flipped {
        transform: scale(0.25, 1);
      }
      .prompt {
        max-width: 20vw;
        padding: 1vw;
        border-radius: 1vw;
        font-size: 3.5vw;
        margin: 1vw 0;
      }
      .nav-links {
        .nav-link {
          filter: drop-shadow(0px 0px 5px black);
          min-width: 20vw;
          font-size: 3vh;
          padding: 1vw;
          margin-top: 1vh;
          border-radius: 1vw;
        }
      }
      .menu-toggle {
        width: 20vw;
        height: 20vw;
        border-radius: 5vw;
        font-size: 12vw;
        border-left: 1.5vw solid lighten($button-red, 7%);
        border-top: 1.5vw solid lighten($button-red, 10%);
        border-bottom: 1.5vw solid darken($button-red, 10%);
        border-right: 1.5vw solid darken($button-red, 7%);
        p {
          margin-top: -2vw;
        }
        &:hover {
        }
      }
      .menu-toggle-active {
        p {
        }
      }
    }
  }
}
h1,
h2,
p {
  margin: 0;
  padding: 0;
}
.test-svg {
  position: fixed;
  top: 0px;
  left: 0px;
}
.engineer {
  position: fixed;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;

  .my-face-container {
    position: fixed;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    bottom: 71px;
    z-index: 9999;
    filter: drop-shadow(15px 15px 20px #0000006a);
    .my-face {
      height: calc($monitor-height * 1.25);
    }
    .my-face-marker-1,
    .my-face-marker-2 {
      position: fixed;
      // border: 5px solid green;
      width: 0px;
      right: 0;
      margin-right: auto;
      margin-left: auto;
    }
    .my-face-marker-1 {
      left: -4vw;
      bottom: 150px;
    }
    .my-face-marker-2 {
      left: 50px;
      bottom: 130px;
    }
  }
  .engineer-body {
    position: fixed;
    background-color: $color_engineer-body;
    border-left: 10px solid lighten($color_engineer-body, 7%);
    border-top: 5px solid lighten($color_engineer-body, 10%);
    width: 200px;
    height: 100px;
    bottom: 15px;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;
    border-radius: 80px 80px 0px 0px;
    filter: drop-shadow(15px 15px 20px #0000006a);
  }
}
.desk {
  position: fixed;
  background-color: $color_desk;
  width: $desk-width;
  height: $desk-height;
  bottom: -10px;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  z-index: 999;
  .desktop {
    position: fixed;
    bottom: 7px;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    width: calc($desk-width + 20px);
    border-bottom: 5px solid darken($color_desk, 10%);
    border-top: 3px solid lighten($color_desk, 10%);
    border-left: 3px solid lighten($color_desk, 6%);
    border-right: 3px solid darken($color_desk, 6%);
    border-radius: 3px;
    height: 25px;
    background-color: $color_desk;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    p {
      font-size: 12px;
      margin: 0px 20px;
    }
  }
}
.monitor-left,
.monitor-right {
  position: fixed;
  bottom: 65px;
  width: $monitor-width;
  height: $monitor-height;
  background-color: $color_monitor;
  border-bottom: 15px solid darken($color_monitor, 10%);
  border-right: 15px solid darken($color_monitor, 6%);
  border-left: 15px solid lighten($color_monitor, 6%);
  border-top: 15px solid lighten($color_monitor, 10%);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  // overflow-y: visible;
  z-index: 1;
}
.monitor-left {
  left: 50%;
  margin-left: -260px;
}
.monitor-right {
  right: 50%;
  margin-right: -260px;
}
.monitor_circle-bracket {
  position: fixed;
  background-color: darken($color_monitor, 5%);
  border-left: 2px solid lighten($color_monitor, 10%);
  border-top: 2px solid lighten($color_monitor, 10%);
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.monitor_base {
  position: fixed;
  bottom: 0px;
  background-color: blue;
  border-radius: 50% 50% 0 0;
  width: 100px;
  height: 50px;
  background-color: $color_monitor;
  border-left: 5px solid lighten($color_monitor, 10%);
  border-top: 5px solid lighten($color_monitor, 10%);
  z-index: 99999999;
}
.monitor_stand-column {
  position: fixed;
  background-color: $color_monitor;
  border-right: 15px solid darken($color_monitor, 6%);
  border-left: 15px solid lighten($color_monitor, 6%);
  border-top: 15px solid lighten($color_monitor, 10%);
  width: 25px;
  height: 90px;
  bottom: 49px;
  z-index: 99999;
  border-radius: 10px 10px 0 0;
}
.laptop {
  position: fixed;
  z-index: 99999;
}
.laptop-screen,
.laptop-base,
.laptop-base-right,
.mouse {
  position: fixed;
  margin-right: auto;
  margin-left: auto;
}
.laptop-screen {
  bottom: 42px;
  left: 290px;
  right: 0;
  width: 150px;
  height: 95px;
  background-color: gray;
  border-radius: 8px;
  transform: skew(-15deg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid lightgray;
  border-top: 2px solid lightgray;
  z-index: 999999;
  .laptop-logo {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    border-right: 2px solid rgb(222, 222, 222);
    border-bottom: 2px solid rgb(222, 222, 222);
    box-sizing: border-box;
  }
}
.laptop-base {
  bottom: 32px;
  left: 45px;
  right: 0;
  width: 70px;
  height: 10px;
  background-color: gray;
  border-radius: 0px 0px 0px 10px;
  border-top: 1px solid lightgray;
  z-index: 9999991;
}
.laptop-base-right {
  bottom: 32px;
  left: 265px;
  right: 0;
  width: 150px;
  height: 10px;
  background-color: rgb(107, 107, 107);
  border-radius: 0px 0px 10px 0px;
  border-top: 1px solid rgb(57, 57, 57);
  z-index: 999999;
}
.mouse {
  width: 50px;
  height: 30px;
  left: -200px;
  right: 0;
  background-color: $color_monitor;
  bottom: 30px;
  border-radius: 50%;
  border-top: 7px solid rgb(245, 216, 221);
  border-right: 5px solid rgb(245, 216, 221);
  z-index: -99;
}
.visible {
  opacity: 100%;
  transition: opacity 0.2s;
}
.invisible {
  opacity: 0%;
  transition: opacity 0.2s;
}
.display-none {
  display: none;
}

// remove for production
// .engineer-at-desk{
//   opacity: 0%;
// }
