$monitor-width: 210px;
$monitor-height: 130px;
$color_monitor: rgb(25, 25, 25);

$desk-width: 600px;
$desk-height: 30px;
$color_desk: rgb(154, 85, 11);
$color_engineer-body: rgb(0, 27, 38);

$name-top: 10px;

$button-red: rgb(174, 43, 43);
$banner-color: rgb(211, 69, 69);
